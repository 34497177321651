import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { db } from "../../firebase";

const ModalConcepts = (props) => {
  const { organization } = props;
  const initialState = {
    title: "",
    description: "",
    tags: "",
    owner: "",
    ownerName: "",
    photoURL: "",
    resourceAmount: 0,
  };

  const [values, setValue] = useState(initialState);
  const [validated, setValidated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };

  const CloseModal = () => {
    setValidated(false);
    props.onHide();
  };

  const IsValidated = () => {
    if (
      values.title !== "" &&
      values.description !== "" &&
      values.tags !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hSubmit = (event) => {
    event.preventDefault();
    let button = document.getElementById("buttonCreate").value;
    if (IsValidated()) {
      if (button === "Create") {
        document.getElementById("buttonCreate").setAttribute("disabled", true);
        document.getElementById("Close").setAttribute("disabled", true);
        setValidated(false);
        handledSubmit();
      } else {
        setValidated(false);
        handleEdit();
      }
    } else {
      setValidated(true);
    }
  };

  const handleEdit = async () => {
    await db
      .collection("organization")
      .doc(props.id)
      .collection("concepts")
      .doc(props.conceptId)
      .update(values)
      .then(() => {
        setValue(initialState);
        props.setButtonText("Create");
        props.setConceptId("");
        props.handleClose();
      })
      .catch((error) => console.error(error));
  };

  const handledSubmit = () => {
    props.setButtonText("Creating...");
    const newConcept = {
      title: values.title,
      description: values.description,
      tags: values.tags,
      owner: sessionStorage.getItem("ID"),
      ownerName: sessionStorage.getItem("user"),
      photoURL: sessionStorage.getItem("avatar"),
      archive: false,
      resourceAmount: values.resourceAmount,
    };
    db.collection("organization")
      .doc(props.id)
      .collection("concepts")
      .add(newConcept)
      .then((docRef) => {
        onNewConceptHitWebhooks({ ...newConcept, id: docRef.id });
        setValue(initialState);
        props.setButtonText("Create");
        props.handleClose();
      })
      .catch((error) => console.error(error));
  };

  const GetInfo = async () => {
    if (
      props.conceptId !== "" &&
      props.conceptId !== undefined &&
      props.id !== "" &&
      props.id !== undefined
    ) {
      const doc = await db
        .collection("organization")
        .doc(props.id)
        .collection("concepts")
        .doc(props.conceptId)
        .get();
      setValue({
        ...doc.data(),
      });
    } else {
      setValue(initialState);
    }
  };

  const onNewConceptHitWebhooks = (concept = {}) => {
    const conceptURL = `https://app.topicbase.io/${organization.slug}/concepts/${concept.id}`;
    const body = {
      type: "create-concept",
      concept: {
        id: concept.id,
        title: concept.title,
        description: concept.description,
        tags: concept.tags,
        resourceAmount: concept.resourceAmount,
        archive: concept.archive,
        url: conceptURL,
      },
      conceptOwner: {
        id: concept.owner,
        name: concept.ownerName,
      },
      organization: {
        id: organization.id,
        name: organization.title,
      },
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const promiseArray = [];
    if (organization.webhooks) {
      organization.webhooks.forEach((url) => {
        promiseArray.push(fetch(url, options));
      });
      Promise.all(promiseArray)
        .then((responses) => {
          console.log("Webhooks hitted: ", responses.length);
        })
        .catch(() => console.log("error when hitting webhooks"));
    }
  };

  useEffect(() => {
    GetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>
          {props.titleText} {props.conceptTitle.toLowerCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={hSubmit} validated={validated} noValidate>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              name="title"
              id="title"
              type="text"
              placeholder={`Name your ${props.conceptTitle.toLowerCase()}`}
              onChange={handleInputChange}
              value={values.title}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a title
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              required
              as="textarea"
              type="textarea"
              className="textareaModal"
              placeholder="Description"
              onChange={handleInputChange}
              value={values.description}
              name="description"
              id="description"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a description for your concept
            </Form.Control.Feedback>
            <div align="right">
              <a
                className="Hyperlink"
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                Markdown help
              </a>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label as="h5">Tags</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="text"
              placeholder="Tags"
              onChange={handleInputChange}
              value={values.tags}
              name="tags"
              id="tags"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide at least one tag
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="Button"
          id="Close"
          variant="secondary"
          onClick={() => {
            CloseModal();
          }}
        >
          Cancel
        </Button>
        <Button
          className="Button"
          id="buttonCreate"
          variant="primary"
          type="submit"
          value={props.buttonText}
          onClick={hSubmit}
        >
          {props.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConcepts;
