import React, { useState, useEffect } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";

function ShowOrganization(props) {
  const { GetInfo, organizations } = props;
  useEffect(() => {
    // ComponentDidMount functionality: call getinfo to retrieve data.
    GetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [orgs, setOrgs] = useState(() => [...organizations]);
  const [search, setSearch] = useState(() => "");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const qValue = queryParams.get("q");
    if (qValue) {
      setSearch(() => qValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const location = {
      pathname: "/",
      search: "",
      state: { fromHome: true },
    };
    if (search) {
      location.search = "?q=" + search;
      history.replace(location);
      const filteredOrganizations = organizations.filter(
        (org) => org.title.toUpperCase().indexOf(search.toUpperCase()) > -1
      );
      setOrgs(() => filteredOrganizations);
    } else {
      history.replace(location);
      setOrgs(() => [...organizations]);
    }
  }, [search, history, organizations]);

  return (
    <div className="App">
      <br />
      <Container>
        <Form.Label as="h2">Search on Te-Fiti</Form.Label>
        <Form.Control
          type="text"
          id="SearchInput"
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Organization"
          value={search}
        ></Form.Control>
      </Container>
      <br />
      <Container>
        <ul id="OrgList">
          {orgs.map((organization) => {
            const location = {
              pathname: `/${organization.slug}`,
              search: "",
              state: {},
            };
            return (
              <li key={organization.id}>
                <div className="col-md-200" key={organization.id}>
                  <Card style={{ color: "#000" }}>
                    <Card.Header as="h3" align="left">
                      <Link className="Hyperlink" id="title" to={location}>
                        {organization.title}
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div align="left">{organization.description}</div>
                    </Card.Body>
                  </Card>
                  <br />
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
}

export default ShowOrganization;
