import React, { useState, useEffect } from "react";
import {
  Form,
  Container,
  Card,
  Button,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
//import ArchiveModalConfirm from "../Interaction/ArchiveConcept";
import RequestLogin from "../Interaction/RequestLogin";

function ShowConcepts(props) {
  const {
    organization,
    concepts,
    concept,
    setTitleText,
    setButtonText,
    handleShow,
    setConceptId,
  } = props;

  const [showRequestLogin, setShowRequestLogin] = useState(false);
  const [search, setSearch] = useState(() => "");
  const [conceptsList, setConceptsList] = useState(() => [...concepts]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const qValue = queryParams.get("q");
    if (organization.slug && qValue) {
      setSearch(() => qValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const location = {
      pathname: `/${organization.slug}`,
      search: "",
      state: {},
    };
    if (search) {
      const filteredConcepts = concepts.filter((c) => {
        const matchesId = c.id.indexOf(search) > -1 ? true : false;
        const matchesTitle =
          c.title.toUpperCase().indexOf(search.toUpperCase()) > -1
            ? true
            : false;
        const matchesTags = c.tags.indexOf(search) > -1 ? true : false;
        return matchesId || matchesTitle || matchesTags;
      });
      setConceptsList(() => filteredConcepts);
      location.search = "?q=" + search;
      history.replace(location);
    } else {
      window.history.pushState({}, "", location.pathname);
      setConceptsList(() => [...concepts]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleCloseRequestLogin = () => {
    setShowRequestLogin(false);
  };

  const ShowModalFromEdit = async (id) => {
    setTitleText("Save");
    setButtonText("Save");
    setConceptId(id);
    handleShow();
  };

  const ShowName = (id, name) => {
    return <Tooltip id={`creator-${id}`}>Created by: {name}</Tooltip>;
  };

  return (
    <>
      <Container>
        <Form.Control
          type="text"
          id="SearchInput"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder={`Search ${organization.conceptP.toLowerCase()}`}
        ></Form.Control>
      </Container>
      {concept ? (
        <>
          <br />
          <Container>
            <ul id="OrgList">
              {conceptsList.map((concept) => (
                <li key={concept.id}>
                  <Card style={{ color: "#000" }}>
                    <Card.Header>
                      <Row>
                        <Col as="h3" align="left">
                          <div id="Part">
                            <Link
                              className="Hyperlink"
                              to={`/${organization.slug}/concepts/${concept.id}`}
                            >
                              {concept.title}
                            </Link>
                          </div>
                        </Col>
                        <Col align="right">
                          <div id="notPart" size="sm">
                            <OverlayTrigger
                              key={concept.id}
                              sm={4}
                              overlay={ShowName(concept.id, concept.ownerName)}
                            >
                              <Image
                                src={concept.photoURL}
                                roundedCircle
                                fluid
                                className="avatarConcept"
                              ></Image>
                            </OverlayTrigger>
                          </div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div align="right" id="notPart">
                            {sessionStorage.getItem("ID") !== null &&
                            sessionStorage.getItem("ID") !== "" ? (
                              concept.owner === sessionStorage.getItem("ID") ? (
                                <>
                                  {/*<Button
                                        className="Button"
                                        variant="outline-secondary"
                                        onClick={() =>
                                          ArchiveConcept(concept.title, concept.id)
                                        }
                                      >
                                        Archive
                                      </Button>*/}
                                  <Button
                                    className="Button"
                                    variant="primary"
                                    onClick={() =>
                                      ShowModalFromEdit(concept.id)
                                    }
                                  >
                                    Edit
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {/*<Button
                                        className="Button"
                                        variant="outline-secondary"
                                        disabled
                                      >
                                        Archive
                                      </Button>*/}
                                  <Button
                                    className="Button"
                                    variant="primary"
                                    disabled
                                  >
                                    Edit
                                  </Button>
                                </>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div align="left" id="Part">
                        {concept.tags}
                      </div>
                      {concept.resourceAmount !== undefined ? (
                        <div align="right" id="notPart">
                          Amount of resources: {concept.resourceAmount}
                        </div>
                      ) : (
                        <div align="right" id="notPart">
                          Waiting for update.
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                  <br />
                </li>
              ))}
            </ul>
          </Container>
        </>
      ) : (
        <>
          <br />
          <h4>
            There are no {organization.conceptP.toLowerCase()} yet, create one.
          </h4>
        </>
      )}
      {/*<ArchiveModalConfirm
        id={props.id}
        conceptId={idArchive}
        name={name}
        title={conceptTitle}
        showArchive={showArchive}
        handleCloseArchive={handleCloseArchive}
      />*/}
      <RequestLogin
        showRequestLogin={showRequestLogin}
        handleCloseRequestLogin={handleCloseRequestLogin}
      />
    </>
  );
}

export default ShowConcepts;
