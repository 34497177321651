//list of languages by country in alphabetic order by code
const Languages = {
  ar: "Arabic",
  "ar-AE": "Arabic (United Arab Emirates)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-DZ": "Arabic (Algeria)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (Libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SD": "Arabic (Sudan)",
  "ar-SY": "Arabic (Syria)",
  "ar-TN": "Arabic ( Tunisia)",
  "ar-YE": "Arabic (Yemen)",
  be: "Belarusian",
  "be-BY": "Belarusian (Belarus)",
  bg: "Bulgarian",
  "bg-BG": "Bulgarian (Bulgaria)",
  ca: "Catalan",
  "ca-ES": "Catalan (Spain)",
  cs: "Czech",
  "cs-CZ": "Czech (Czech)",
  da: "Danish",
  "da-DK": "Danish (Denmark)",
  de: "German",
  "de-AT": "German (Austria)",
  "de-CH": "German (Switzerland)",
  "de-DE": "German (Germany)",
  "de-LU": "German (Luxembourg)",
  el: "Greek",
  "el-CY": "Greek (Cyprus )",
  "el-GR": "Greek (Greece)",
  en: "English",
  "en-AU": "English (Australia)",
  "en-CA": "English (Canada)",
  "en-GB": "English (UK)",
  "en-IE": "English (Ireland)",
  "en-IN": "English (India)",
  "en-MT": "English (Malta)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Philippines)",
  "en-SG": "English (Singapore)",
  "en-US": "English (United States)",
  "en-ZA": "English (South Africa)",
  es: "Spanish",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-ES": "Spanish (Spain)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-PY": "Spanish (Paraguay)",
  "es-SV": "Spanish (El Salvador)",
  "es-US": "Spanish (United States)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  "et-EE": "Estonian (Estonia)",
  fi: "Finnish",
  "fi-FI": "Finnish (Finland)",
  fr: "French",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-CH": "French (Switzerland)",
  "fr-FR": "French (France)",
  "fr-LU": "French (Luxembourg)",
  ga: "Irish",
  "ga-IE": "Irish (Ireland)",
  hi: "Hindu",
  "hi-IN": "Hindu (India)",
  hr: "Croatian",
  "hr-HR": "Croatian (Croatia)",
  "hu-HU": "Hungarian (Hungary)",
  hu: "Hungarian",
  "in-ID": "Indonesian (Indonesia)",
  in: "Indonesian",
  "is-IS": "Icelandic (Iceland)",
  is: "Icelandic",
  "it-CH": "Italian (Switzerland)",
  it: "Italian",
  "it-IT": "Italian (Italy)",
  "iw-IL": "Hebrew (Israel)",
  iw: "Hebrew",
  "ja-JP": "Japanese (Japan)",
  ja: "Japanese",
  "ja-JP-JP": "Japanese (Japan, JP)",
  "ko-KR": "Korean (South Korea)",
  ko: "Korean",
  "lt-LT": "Lithuanian (Lithuania)",
  lt: "Lithuanian",
  "lv-LV": "Latvian (Latvia)",
  lv: "Latvian",
  "mk-MK": "Macedonian (Macedonia)",
  mk: "Macedonian",
  "ms-MY": "Malay (Malaysia)",
  ms: "Malay",
  "mt-MT": "Maltese (Malta)",
  mt: "Maltese",
  "nl-BE": "Dutch (Belgium)",
  nl: "Dutch",
  "nl-NL": "Dutch (Netherlands)",
  "no-NO": "Norwegian (Norway)",
  no: "Norwegian",
  "no-NO-NY": "Norwegian (Norway, Nynorsk)",
  "pl-PL": "Polish (Poland)",
  pl: "Polish",
  "pt-BR": "Portuguese (Brazil)",
  pt: "Portuguese",
  "pt-PT": "Portuguese (Portugal)",
  "ro-RO": "Romanian (Romania)",
  ro: "Romanian",
  "ru-RU": "Russian (Russia)",
  ru: "Russian",
  "sk-SK": "Slovak (Slovakia)",
  sk: "Slovak",
  "sl-SI": "Slovenian (Slovenia)",
  sl: "Slovenian",
  "sq-AL": "Albanian (Albania)",
  sq: "Albanian",
  "sr-BA": "Serbian (Bosnia and Herzegovina)",
  sr: "Serbian",
  "sr-CS": "Serbian (Serbia and Montenegro)",
  "sr-ME": "Serbian ( Montenegro)",
  "sr-RS": "Serbian (Serbia)",
  "sv-SE": "Swedish (Sweden)",
  sv: "Swedish",
  "th-TH": "Thai (Thailand)",
  th: "Thai",
  "th-TH-TH": "Thai (Thailand, TH)",
  "tr-TR": "Turkish (Turkey)",
  tr: "Turkish",
  "uk-UA": "Ukrainian ( Ukraine)",
  uk: "Ukrainian ",
  "vi-VN": "Vietnamese (Vietnam)",
  vi: "Vietnamese",
  "zh-CN": "Chinese (China)",
  zh: "Chinese",
  "zh-HK": "Chinese (Hong Kong)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (Taiwan)",
};

//list of languages by country in alphabetic order by name
const LanguagesCode = {
  "sq-AL": "Albanian (Albania)",
  "ar-TN": "Arabic ( Tunisia)",
  "ar-DZ": "Arabic (Algeria)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (Libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SD": "Arabic (Sudan)",
  "ar-SY": "Arabic (Syria)",
  "ar-AE": "Arabic (United Arab Emirates)",
  "ar-YE": "Arabic (Yemen)",
  "be-BY": "Belarusian (Belarus)",
  "bg-BG": "Bulgarian (Bulgaria)",
  "ca-ES": "Catalan (Spain)",
  "zh-CN": "Chinese (China)",
  "zh-HK": "Chinese (Hong Kong)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (Taiwan)",
  "hr-HR": "Croatian (Croatia)",
  "cs-CZ": "Czech (Czech)",
  "da-DK": "Danish (Denmark)",
  "nl-BE": "Dutch (Belgium)",
  "nl-NL": "Dutch (Netherlands)",
  "en-AU": "English (Australia)",
  "en-CA": "English (Canada)",
  "en-IN": "English (India)",
  "en-IE": "English (Ireland)",
  "en-MT": "English (Malta)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Philippines)",
  "en-SG": "English (Singapore)",
  "en-ZA": "English (South Africa)",
  "en-GB": "English (UK)",
  "en-US": "English (United States)",
  "et-EE": "Estonian (Estonia)",
  "fi-FI": "Finnish (Finland)",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-FR": "French (France)",
  "fr-LU": "French (Luxembourg)",
  "fr-CH": "French (Switzerland)",
  "de-AT": "German (Austria)",
  "de-DE": "German (Germany)",
  "de-LU": "German (Luxembourg)",
  "de-CH": "German (Switzerland)",
  "el-CY": "Greek (Cyprus )",
  "el-GR": "Greek (Greece)",
  "iw-IL": "Hebrew (Israel)",
  "hi-IN": "Hindu (India)",
  "hu-HU": "Hungarian (Hungary)",
  "is-IS": "Icelandic (Iceland)",
  "in-ID": "Indonesian (Indonesia)",
  "ga-IE": "Irish (Ireland)",
  "it-IT": "Italian (Italy)",
  "it-CH": "Italian (Switzerland)",
  "ja-JP": "Japanese (Japan)",
  "ja-JP-JP": "Japanese (Japan, JP)",
  "ko-KR": "Korean (South Korea)",
  "lv-LV": "Latvian (Latvia)",
  "lt-LT": "Lithuanian (Lithuania)",
  "mk-MK": "Macedonian (Macedonia)",
  "ms-MY": "Malay (Malaysia)",
  "mt-MT": "Maltese (Malta)",
  "no-NO": "Norwegian (Norway)",
  "no-NO-NY": "Norwegian (Norway, Nynorsk)",
  "pl-PL": "Polish (Poland)",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "ro-RO": "Romanian (Romania)",
  "ru-RU": "Russian (Russia)",
  "sr-ME": "Serbian ( Montenegro)",
  "sr-BA": "Serbian (Bosnia and Herzegovina)",
  "sr-CS": "Serbian (Serbia and Montenegro)",
  "sr-RS": "Serbian (Serbia)",
  "sk-SK": "Slovak (Slovakia)",
  "sl-SI": "Slovenian (Slovenia)",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-SV": "Spanish (El Salvador)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PY": "Spanish (Paraguay)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-ES": "Spanish (Spain)",
  "es-US": "Spanish (United States)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  "sv-SE": "Swedish (Sweden)",
  "th-TH": "Thai (Thailand)",
  "th-TH-TH": "Thai (Thailand, TH)",
  "tr-TR": "Turkish (Turkey)",
  "uk-UA": "Ukrainian ( Ukraine)",
  "vi-VN": "Vietnamese (Vietnam)",
};

//litle function to wait the second that the user send in miliseconds
const wait = (ms) => {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
};

const Order = (resourceInfo) => {
  let LanguageListEvaluate = [];
  let i = 0;
  //save just the first 2 caracter of a complete code to match with the language without restriction of country
  //for "es-ES"just catch "es"that means Spanish for all countries.
  for (i; i < resourceInfo.length; i++) {
    LanguageListEvaluate.push(resourceInfo[i].language.substr(0, 2));
  }
  //delete duplicate
  const List = LanguageListEvaluate.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);
  return List;
};

/* //A litle function to order the languages by country 
const Alphabetic = () => {
  let languageTemp = Object.entries(LanguagesCode);
  languageTemp.sort(function (a, b) {
    const A = a[1].toLowerCase();
    const B = b[1].toLowerCase();
    if (A > B) {
      return 1;
    }
    if (A < B) {
      return -1;
    }
    return 0;
  });

  console.log(languageTemp);
};*/

export { Languages, LanguagesCode, wait, Order };
